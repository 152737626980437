import { Environment } from '../types';

export const environment: Environment = {
  isProduction: true,
  mtbeEndpoint: ' https://api.app.qualio.com',
  authApiEndpoint: 'https://auth-exchange.app.qualio.com',
  name: 'production',
  loginUrl: `https://${window.location.host}/`,
  authSuccessRefreshIntervalMillis: 60000,
  mtfeEndpoint: `https://${window.location.host}`,
  ldClientSideId: '61e6a31060139c1610d1516c',
};
